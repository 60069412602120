
import i18n from '@/vueI18n';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'app-telecom-view-page',
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      record: 'telecom/view/record',
      loading: 'telecom/view/loading',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
    evenNumbers() {
      if (this.record) {
        return this.record.images
          .map((_, i) => i)
          .filter((i) => i % 3 === 0);
      }
      return [];
    },
    options() {
      return {
        rewind: true,
        gap: '1.25rem',
        perPage: 2,
        breakpoints: {
          640: {
            perPage: 1,
            gap: '1rem',
          },
          480: {
            perPage: 1,
            gap: '1rem',
          },
        },
      };
    },
  },
  data() {
    return {
      slide: 0,
      toggler: false,
      modalImages: [],
      captionArray: [],
      slideIndex: 0,
      starbucks: {
        id: 'yhvpDrSiqDPyD5UpjsUn',
        name: { ar: 'ستاربكس', en: 'Starbucks' },
        description: {
          ar: 'لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع الكثير من الملاحظات او الانتقادات للتصميم الاساسيوخلافاَ للاعتقاد السائد فإن لوريم إيبسوم ليس نصاَ عشوائياً، بل إن له جذور في الأدب اللاتيني الكلاسيكي منذ العام 45 قبل الميلاد. من كتاب "حول أقاصي الخير والشر',
          en: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        },
        backgroundImage:
          'https://te.eg/wps/wcm/connect/bbc8889b-5d65-49cd-a6a8-20f67af09cf1/Agd3-Sha7na---Promotion-Page-1252-W-x-322-H---EN.png?MOD=AJPERES&CACHEID=bbc8889b-5d65-49cd-a6a8-20f67af09cf1',
        images: [
          '/images/starbucks-menu.jpeg',
          '/images/starbucks-menu.jpeg',
          '/images/starbucks-menu.jpeg',
        ],
        thumbnail: '/images/starbucks-banner.png',
        location: 'Smouha Gate 2',
        phoneNumber: '+201223141701',
        openHours: 'Everyday 9 AM - 11 PM',
        adminName: { en: 'asdasd', ar: 'asdasd' },
      },
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
      },
    };
  },
  components: {},
  methods: {
    openImageModal(index) {
      this.toggler = !this.toggler;
      this.slideIndex = index;
    },
    ...mapActions({
      doFind: 'telecom/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    getFirstWordPresenter(description) {
      if (this.isRTL) {
        return this.getFirstWord(description['ar']);
      } else {
        return this.getFirstWord(description['en']);
      }
    },
    getFirstWord(str) {
      let spaceIndex = str.indexOf(' ');
      return spaceIndex === -1
        ? str
        : str.substr(0, spaceIndex);
    },
    getSecondWordPresenter(description) {
      if (this.isRTL) {
        return this.getSecondWord(description['ar']);
      } else {
        return this.getSecondWord(description['en']);
      }
    },
    getSecondWord(str) {
      return str.split(/\s+/).slice(1, 2).toString();
    },
    removeFirstTwoWord(description) {
      let str = this.isRTL
        ? description['ar']
        : description['en'];
      str = str.split(/\s+/).slice(2, str.length);
      return str.join(' ');
    },
    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en'];
    },
  },
  async created() {
    await this.doFind(this.$route.params.id);
    console.log('record', this.record);
  },
};
